import React, { lazy, Suspense } from 'react';

const LazyProofDialog = lazy(() =>
  import('./components/proofDialog').then((module) => ({ default: module.ProofDialog }))
);

const LazyGenStudioSetup = lazy(() =>
  import('./pages/genStudioSetup').then((module) => ({ default: module.GenStudioSetup }))
);

export function ProofDialog(props) {
  return (
    <Suspense fallback={null}>
      <LazyProofDialog {...props} />
    </Suspense>
  );
}

export function GenStudioSetup(props) {
  return (
    <Suspense fallback={null}>
      <LazyGenStudioSetup {...props} />
    </Suspense>
  );
}
